/*************************************************************************
 *
 * CONFIDENTIAL
 * __________________
 *
 * [2019] - [2020] Budee Inc
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Budee Inc and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Budee Inc
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Budee Inc.
 */

import classnames from 'classnames/bind'
import styles from './core/index.less'

export const classCtx = classnames.bind(styles)
export default classCtx
